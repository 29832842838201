export const state = {
    storeInquiryFormAdults: 2,
    storeInquiryFormChildren: 0,
    storeInquiryFormChildrenAges: [],
    storeInquiryFormChildrenAgesCount: 0,
    storeInquiryFormPets: false,
    storeInquiryFormStartDate: null,
    storeInquiryFormEndDate: null,
    storeInquiryFormSpinnerActive: false,
    fetching: true,
    facility: {},
    accommodations: {},
}

export const mutations = {
    SET_INQUIRY_FORM_ADULTS(state, value) {
        state.storeInquiryFormAdults = value;
        //console.log('Store Inquiry Form Adults: ' + state.storeInquiryFormAdults);
    },
    SET_INQUIRY_FORM_CHILDREN(state, value) {
        state.storeInquiryFormChildren = value;
        //console.log('Store Inquiry Form Children: ' + state.storeInquiryFormChildren);
    },
    SET_INQUIRY_FORM_CHILDREN_AGES(state, value) {
        if(Array.isArray(value)) {
            state.storeInquiryFormChildrenAges = [...value];
        } else {
            state.storeInquiryFormChildrenAges = [];
            let valueSplit = value.split(',');
            for (let i = 0; i < value.split(',').length; i++) {
                state.storeInquiryFormChildrenAges.push(valueSplit[i]);
            }
        }
        // console.log('Store Inquiry Form Children Ages updated: ' + state.storeInquiryFormChildrenAges);
    },
    UPDATE_INQUIRY_FORM_CHILDREN_AGES(state, value) {
        let tempValue= state.storeInquiryFormChildrenAges;
        state.storeInquiryFormChildrenAges = [];
        state.storeInquiryFormChildrenAges = tempValue;
        state.storeInquiryFormChildrenAges[value.index] = value.alter;
        //console.log('Store Inquiry Form Children Ages updated: ' + state.storeInquiryFormChildrenAges);
    },
    UPDATE_INQUIRY_FORM_CHILDREN_AGES_COUNT(state) {
        if (Array.isArray(state.storeInquiryFormChildrenAges)) {
            state.storeInquiryFormChildrenAgesCount = 0;
            state.storeInquiryFormChildrenAges.forEach(
                (age) => {
                    if(age){
                        state.storeInquiryFormChildrenAgesCount ++;
                    }
                }
            )
        }
        //console.log('Store Inquiry Form Children Ages Count: ' + state.storeInquiryFormChildrenAgesCount);
    },
    CHECK_INQUIRY_FORM_CHILDREN_AGES(state, value) {
        let countChildren = value;
        let countChildrenAges = state.storeInquiryFormChildrenAges.length;
        if (countChildren < countChildrenAges) {
            let diff = countChildrenAges - countChildren;
            for (let i = 0; i < diff; i++) {
                state.storeInquiryFormChildrenAges.pop();
            }
        }
        //console.log('Store Check Inquiry Form Children Ages: ' + state.storeInquiryFormChildren + ' / ' + state.storeInquiryFormChildrenAges);
    },
    SET_INQUIRY_FORM_PETS(state, value) {
        state.storeInquiryFormPets = value;
        //console.log('Store Inquiry Form Pets: ' + state.storeInquiryFormPets);
    },
    SET_INQUIRY_FORM_START_DATE(state, value) {
        state.storeInquiryFormStartDate = value;
        //console.log('Store Inquiry Form Startdate: ' + state.storeInquiryFormStartDate);
    },
    SET_INQUIRY_FORM_END_DATE(state, value) {
        state.storeInquiryFormEndDate = null;
        state.storeInquiryFormEndDate = value;
        //console.log('Store Inquiry Form Enddate: ' + state.storeInquiryFormEndDate);
    },
    SET_INQUIRY_FORM_SPINNER_ACTIVE(state, value) {
        state.storeInquiryFormSpinnerActive = value;
        //console.log('Store Inquiry Form SpinnerActive: ' + state.storeInquiryFormSpinnerActive);
    },
    SET_INQUIRY_STATE(state, value) {
        const newState = { ...state, ...value };
        if (value.accommodations && typeof value.accommodations === 'object') {
            newState.accommodations = {};
            for (const key in value.accommodations) {
                if (value.accommodations.hasOwnProperty(key)) {
                    const newAccommodation = value.accommodations[key];
                    const existingAccommodation = state.accommodations[key] || {};
                    newState.accommodations[key] = {
                        ...newAccommodation,
                        extended: existingAccommodation.extended !== undefined ? existingAccommodation.extended : false
                    };
                }
            }
        }
        newState.storeInquiryFormAdults = state.storeInquiryFormAdults;
        newState.storeInquiryFormChildren = state.storeInquiryFormChildren;
        newState.storeInquiryFormChildrenAges = state.storeInquiryFormChildrenAges;
        newState.storeInquiryFormPets = state.storeInquiryFormPets;
        newState.storeInquiryFormStartDate = state.storeInquiryFormStartDate;
        newState.storeInquiryFormEndDate = state.storeInquiryFormEndDate;
        newState.storeInquiryFormSpinnerActive = state.storeInquiryFormSpinnerActive;

        Object.assign(state, newState);
        // console.log('Store state:', state);
    },
    SET_INQUIRY_EXTENDED(state, accommodationId) {
        state.accommodations[accommodationId].extended = !state.accommodations[accommodationId].extended;
    },
    SET_INQUIRY_FETCHING(state, value) {
        state.fetching = value;
    }
}

export const actions = {
    setInquiryFormAdults(context, value) {
        context.commit('SET_INQUIRY_FORM_ADULTS', value);
    },
    setInquiryFormChildren(context, value) {
        context.commit('SET_INQUIRY_FORM_CHILDREN', value);
        context.commit('CHECK_INQUIRY_FORM_CHILDREN_AGES', value);
        context.commit('UPDATE_INQUIRY_FORM_CHILDREN_AGES_COUNT');
    },
    setInquiryFormChildrenAges(context, value) {
        context.commit('SET_INQUIRY_FORM_CHILDREN_AGES', value);
        context.commit('UPDATE_INQUIRY_FORM_CHILDREN_AGES_COUNT');
    },
    updateInquiryFormChildrenAges(context, value) {
        context.commit('UPDATE_INQUIRY_FORM_CHILDREN_AGES', value);
        context.commit('UPDATE_INQUIRY_FORM_CHILDREN_AGES_COUNT');
    },
    setInquiryFormPets(context, value) {
        context.commit('SET_INQUIRY_FORM_PETS', value);
    },
    setInquiryFormStartDate(context, value) {
        context.commit('SET_INQUIRY_FORM_START_DATE', value);
    },
    setInquiryFormEndDate(context, value) {
        context.commit('SET_INQUIRY_FORM_END_DATE', value);
    },
    setInquiryFormSpinnerActive(context, value) {
        context.commit('SET_INQUIRY_FORM_SPINNER_ACTIVE', value);
    },
    setStateInquiryForm(context, value) {
        context.commit('SET_INQUIRY_STATE', value);
    },
    setExtendedInquiryForm(context, accommodationId) {
        context.commit('SET_INQUIRY_EXTENDED', accommodationId);
    },
    setFetchingInquiryForm(context, value) {
        context.commit('SET_INQUIRY_FETCHING', value);
    }
}