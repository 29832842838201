export const state = {
    fetching: true,
    facility: {},
    accommodations: {},
}

export const mutations = {
    SET_STATE(state, value) {
        const newState = { ...state, ...value };
        if (value.accommodations && typeof value.accommodations === 'object') {
            newState.accommodations = {};
            for (const key in value.accommodations) {
                if (value.accommodations.hasOwnProperty(key)) {
                    const newAccommodation = value.accommodations[key];
                    const existingAccommodation = state.accommodations[key] || {};
                    newState.accommodations[key] = {
                        ...newAccommodation,
                        extended: existingAccommodation.extended !== undefined ? existingAccommodation.extended : false
                    };
                }
            }
        }
        Object.assign(state, newState);
        // console.log('Store state:', state);
    },
    SET_EXTENDED(state, accommodationId) {
        state.accommodations[accommodationId].extended = !state.accommodations[accommodationId].extended;
    },
    SET_FETCHING(state, value) {
        state.fetching = value;
    }
}

export const actions = {
    setState(context, value) {
        context.commit('SET_STATE', value);
    },
    setExtended(context, accommodationId) {
        context.commit('SET_EXTENDED', accommodationId);
    },
    setFetching(context, value) {
        context.commit('SET_FETCHING', value);
    }
}