export const state = {
    storeAdults: 2,
    storeChildren: 0,
    storeChildrenAges: [],
    storeChildrenAgesCount: 0,
    storePets: false,
    storeStartDate: null,
    storeEndDate: null,
    itsInquiryForm: false,
    storePreviousDestination: {
        id: null,
        name: null,
        slug: null,
        type: null
    },
    storeDestination: {
        id: null,
        name: null,
        slug: null,
        type: null
    },
    storeNewDestination: {
        id: null,
        name: null,
        slug: null,
        type: null
    }
}

export const mutations = {
    SET_ADULTS(state, value) {
        state.storeAdults = value;
        // console.log('Store Adults: ' + state.storeAdults);
    },
    SET_CHILDREN(state, value) {
        state.storeChildren = value;
        // console.log('Store Children: ' + state.storeChildren);
    },
    SET_CHILDREN_AGES(state, value) {
        if(Array.isArray(value)) {
            state.storeChildrenAges = [...value];
        } else {
            state.storeChildrenAges = [];
            let valueSplit = value.split(',');
            for (let i = 0; i < value.split(',').length; i++) {
                state.storeChildrenAges.push(valueSplit[i]);
            }
        }
        // console.log('Store Children Ages set: ' + state.storeChildrenAges);
    },
    UPDATE_CHILDREN_AGES(state, value) {
        let tempValue= state.storeChildrenAges;
        state.storeChildrenAges = [];
        state.storeChildrenAges = tempValue;
        state.storeChildrenAges[value.index] = value.alter;
        // console.log('Store Children Ages updated: ' + state.storeChildrenAges);
    },
    UPDATE_CHILDREN_AGES_COUNT(state) {
        if (Array.isArray(state.storeChildrenAges)) {
            state.storeChildrenAgesCount = 0;
            state.storeChildrenAges.forEach(
                (age) => {
                    if(age){
                        state.storeChildrenAgesCount ++;
                    }
                }
            )
        }
        // console.log('Store Children Ages Count: ' + state.storeChildrenAgesCount);
    },
    CHECK_CHILDREN_AGES(state, value) {
        let countChildren = value;
        let countChildrenAges = state.storeChildrenAges.length;
        if (countChildren < countChildrenAges) {
            let diff = countChildrenAges - countChildren;
            for (let i = 0; i < diff; i++) {
                state.storeChildrenAges.pop();
            }
        }
        // console.log('Store Check Children Ages: ' + state.storeChildren + ' / ' + state.storeChildrenAges);
    },
    SET_PETS(state, value) {
        state.storePets = value;
        // console.log('Store Pets: ' + state.storePets);
    },
    SET_START_DATE(state, value) {
        state.storeStartDate = value;
        // console.log('Store Startdate: ' + state.storeStartDate);
    },
    SET_END_DATE(state, value) {
        state.storeEndDate = null;
        state.storeEndDate = value;
        // console.log('Store Enddate: ' + state.storeEndDate);
    },
    SET_INQUIRY_FORM(state, value) {
        state.itsInquiryForm = value;
        // console.log('Store Inquiry Form: ' + state.itsInquiryForm);
    },
    SET_DESTINATION(state, value) {
        state.storeDestination.id = value.id || null;
        state.storeDestination.name = value.name || null;
        state.storeDestination.slug = value.slug || null;
        state.storeDestination.type = value.type || null;
        // console.log('Store Destination: ', state.storeDestination);
    },
    SET_DESTINATION_NAME(state, value) {
        state.storeDestination.name = value;
        // console.log('Store Destination after Name: ', state.storeDestination);
    },
    DELETE_DESTINATION(state, value) {
        state.storeDestination = {
            id: null,
            name: null,
            slug: null,
            type: null
        }
        // console.log('Store Destination: ', state.storeDestination);
    },
    SET_PREVIOUS_DESTINATION(state, value) {
        state.storePreviousDestination.id = value.id || null;
        state.storePreviousDestination.name = value.name || null;
        state.storePreviousDestination.slug = value.slug || null;
        state.storePreviousDestination.type = value.type || null;
        // console.log('Store Previous Destination: ', state.storePreviousDestination);
    },
    SET_PREVIOUS_DESTINATION_NAME(state, value) {
        state.storePreviousDestination.name = value;
        // console.log('Store Previous Destination after Name: ', state.storePreviousDestination);
    },
    SET_NEW_DESTINATION(state, value) {
        state.storeNewDestination.id = value.id || null;
        state.storeNewDestination.name = value.name || null;
        state.storeNewDestination.slug = value.slug || null;
        state.storeNewDestination.type = value.type || null;
        // console.log('Store New Destination: ', state.storeNewDestination);
    },
    SET_NEW_DESTINATION_NAME(state, value) {
        state.storeNewDestination.name = value;
        // console.log('Store New Destination after Name: ', state.storeNewDestination);
    },
}

export const actions = {
    setAdults(context, value) {
        context.commit('SET_ADULTS', value);

        context.commit('SET_INQUIRY_FORM_ADULTS', value);
    },
    setChildren(context, value) {
        context.commit('SET_CHILDREN', value);
        context.commit('CHECK_CHILDREN_AGES', value);
        context.commit('UPDATE_CHILDREN_AGES_COUNT');

        context.commit('SET_INQUIRY_FORM_CHILDREN', value);
        context.commit('CHECK_INQUIRY_FORM_CHILDREN_AGES', value);
        context.commit('UPDATE_INQUIRY_FORM_CHILDREN_AGES_COUNT');
    },
    setChildrenAges(context, value) {
        context.commit('SET_CHILDREN_AGES', value);
        context.commit('UPDATE_CHILDREN_AGES_COUNT');

        context.commit('SET_INQUIRY_FORM_CHILDREN_AGES', value);
        context.commit('UPDATE_INQUIRY_FORM_CHILDREN_AGES_COUNT');
    },
    updateChildrenAges(context, value) {
        context.commit('UPDATE_CHILDREN_AGES', value);
        context.commit('UPDATE_CHILDREN_AGES_COUNT');

        context.commit('UPDATE_INQUIRY_FORM_CHILDREN_AGES', value);
        context.commit('UPDATE_INQUIRY_FORM_CHILDREN_AGES_COUNT');
    },
    setPets(context, value) {
        context.commit('SET_PETS', value);

        context.commit('SET_INQUIRY_FORM_PETS', value);
    },
    setStartDate(context, value) {
        context.commit('SET_START_DATE', value);

        context.commit('SET_INQUIRY_FORM_START_DATE', value);
    },
    setEndDate(context, value) {
        context.commit('SET_END_DATE', value);

        context.commit('SET_INQUIRY_FORM_END_DATE', value);
    },
    setInquiryForm(context, value) {
        context.commit('SET_INQUIRY_FORM', value);
    },
    setDestination(context, value) {
        context.commit('SET_DESTINATION', value);
    },
    setDestinationName(context, value) {
        context.commit('SET_DESTINATION_NAME', value);
    },
    updateTimespan(context, value) {
        if (value.fromDate !== null) {
            context.commit('SET_START_DATE', new Date(value.fromDate));
            context.commit('SET_END_DATE', new Date(value.toDate));
        } else {
            context.commit('SET_START_DATE', value.fromDate);
            context.commit('SET_END_DATE', value.toDate);
        }
    },
    updateTraveling(context, value) {
        context.commit('SET_ADULTS', value.adults);
        context.commit('SET_CHILDREN', value.children);
        context.commit('SET_CHILDREN_AGES', value.childrenages);
        context.commit('UPDATE_CHILDREN_AGES_COUNT');
        context.commit('SET_PETS', value.pets);
    },
    deleteDestination(context, value) {
        context.commit('DELETE_DESTINATION', value);
    },
    setPreviousDestination(context, value) {
        context.commit('SET_PREVIOUS_DESTINATION', value);
    },
    setPreviousDestinationName(context, value) {
        context.commit('SET_PREVIOUS_DESTINATION_NAME', value);
    },
    setNewDestination(context, value) {
        context.commit('SET_NEW_DESTINATION', value);
    },
    setNewDestinationName(context, value) {
        context.commit('SET_NEW_DESTINATION_NAME', value);
    }
}